import {
  WellKnownPropertyType as RelayWellKnownPropertyType,
  CustomPropertyType as RelayCustomPropertyType,
} from './utils/__generated__/separateOptionTypes_UserProperties_SettingsMfe_query.graphql';
import { SubscriberUploadClientIdType as RelaySubscriberUploadClientIdType } from './utils/__generated__/buildTypeOptions_SubscriberUploader_SubscriberUploadFile.graphql';

// Export basic generated types here instead of forcing all consumers to import the generated file
export type {
  RelayWellKnownPropertyType,
  RelayCustomPropertyType,
  RelaySubscriberUploadClientIdType,
};

// Build well known property typing
// Place each value in a typed constant. These values are used within the constants or exported in an object
const WELL_KNOWN_PROPERTY_CITY: RelayWellKnownPropertyType = 'WELL_KNOWN_PROPERTY_CITY';
const WELL_KNOWN_PROPERTY_COUNTRY: RelayWellKnownPropertyType = 'WELL_KNOWN_PROPERTY_COUNTRY';
const WELL_KNOWN_PROPERTY_FIRST_NAME: RelayWellKnownPropertyType = 'WELL_KNOWN_PROPERTY_FIRST_NAME';
const WELL_KNOWN_PROPERTY_FULL_NAME: RelayWellKnownPropertyType = 'WELL_KNOWN_PROPERTY_FULL_NAME';
const WELL_KNOWN_PROPERTY_LAST_NAME: RelayWellKnownPropertyType = 'WELL_KNOWN_PROPERTY_LAST_NAME';
const WELL_KNOWN_PROPERTY_REGION: RelayWellKnownPropertyType = 'WELL_KNOWN_PROPERTY_REGION';
const WELL_KNOWN_PROPERTY_TYPES_KLAVIYO_LAST_EMAIL_OPEN: RelayWellKnownPropertyType =
  'WELL_KNOWN_PROPERTY_TYPES_KLAVIYO_LAST_EMAIL_OPEN';
const WELL_KNOWN_PROPERTY_TYPES_MAILCHIMP_MEMBER_RATING: RelayWellKnownPropertyType =
  'WELL_KNOWN_PROPERTY_TYPES_MAILCHIMP_MEMBER_RATING';
const WELL_KNOWN_PROPERTY_TYPES_SALESFORCE_SERVICE_CLOUD_CONTACT_ID: RelayWellKnownPropertyType =
  'WELL_KNOWN_PROPERTY_TYPES_SALESFORCE_SERVICE_CLOUD_CONTACT_ID';
const WELL_KNOWN_PROPERTY_TIMEZONE: RelayWellKnownPropertyType = 'WELL_KNOWN_PROPERTY_TIMEZONE';
const WELL_KNOWN_PROPERTY_TYPES_UNKNOWN: RelayWellKnownPropertyType =
  'WELL_KNOWN_PROPERTY_TYPES_UNKNOWN';
const WELL_KNOWN_PROPERTY_ZIP: RelayWellKnownPropertyType = 'WELL_KNOWN_PROPERTY_ZIP';
const WELL_KNOWN_PROPERTY_LANGUAGE: RelayWellKnownPropertyType = 'WELL_KNOWN_PROPERTY_LANGUAGE';

// Combine consts into single object for ease of use. This object is now properly typed.
// TS will autocomplete, knows the values are RelayWellKnownPropertyType and will flag instances where we use a non-existent key
export const WellKnownPropertyType = {
  city: WELL_KNOWN_PROPERTY_CITY,
  country: WELL_KNOWN_PROPERTY_COUNTRY,
  firstName: WELL_KNOWN_PROPERTY_FIRST_NAME,
  fullName: WELL_KNOWN_PROPERTY_FULL_NAME,
  lastName: WELL_KNOWN_PROPERTY_LAST_NAME,
  region: WELL_KNOWN_PROPERTY_REGION,
  klaviyoLastEmail: WELL_KNOWN_PROPERTY_TYPES_KLAVIYO_LAST_EMAIL_OPEN,
  mailchimpRating: WELL_KNOWN_PROPERTY_TYPES_MAILCHIMP_MEMBER_RATING,
  salesForceCloudContact: WELL_KNOWN_PROPERTY_TYPES_SALESFORCE_SERVICE_CLOUD_CONTACT_ID,
  timezone: WELL_KNOWN_PROPERTY_TIMEZONE,
  unknown: WELL_KNOWN_PROPERTY_TYPES_UNKNOWN,
  zip: WELL_KNOWN_PROPERTY_ZIP,
  language: WELL_KNOWN_PROPERTY_LANGUAGE,
};

export const WellKnownPropertyMap: Partial<Record<RelayWellKnownPropertyType, string>> = {
  [WellKnownPropertyType.city]: 'City',
  [WellKnownPropertyType.country]: 'Country',
  [WellKnownPropertyType.firstName]: 'First Name',
  [WellKnownPropertyType.fullName]: 'Full Name',
  [WellKnownPropertyType.lastName]: 'Last Name',
  [WellKnownPropertyType.region]: 'State / Region',
  [WellKnownPropertyType.klaviyoLastEmail]: 'Klaviyo Last Email Open',
  [WellKnownPropertyType.mailchimpRating]: 'Mailchimp Member Rating',
  [WellKnownPropertyType.salesForceCloudContact]: 'Salesforce Service Cloud Contact ID',
  [WellKnownPropertyType.timezone]: 'Timezone',
  [WellKnownPropertyType.zip]: 'Zip code',
  [WellKnownPropertyType.language]: 'Language',
};

// Build client ID typing
const SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_CLIENT_USER_ID: RelaySubscriberUploadClientIdType =
  'SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_CLIENT_USER_ID';
const SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_CUSTOM_ID: RelaySubscriberUploadClientIdType =
  'SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_CUSTOM_ID';
const SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_KLAVIYO_ID: RelaySubscriberUploadClientIdType =
  'SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_KLAVIYO_ID';
const SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_SHOPIFY_ID: RelaySubscriberUploadClientIdType =
  'SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_SHOPIFY_ID';
const SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_SALESFORCE_MARKETING_CLOUD_SUBSCRIBER_ID: RelaySubscriberUploadClientIdType =
  'SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_SALESFORCE_MARKETING_CLOUD_SUBSCRIBER_ID';
const SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_CORDIAL_CUSTOMER_ID: RelaySubscriberUploadClientIdType =
  'SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_CORDIAL_CUSTOMER_ID';
const SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_UNKNOWN: RelaySubscriberUploadClientIdType =
  'SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_UNKNOWN';

export const ClientIdType = {
  clientId: SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_CLIENT_USER_ID,
  customId: SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_CUSTOM_ID,
  klaviyoId: SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_KLAVIYO_ID,
  shopifyId: SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_SHOPIFY_ID,
  salesforceMarketingCloudSubscriberId:
    SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_SALESFORCE_MARKETING_CLOUD_SUBSCRIBER_ID,
  cordialCustomerId: SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_CORDIAL_CUSTOMER_ID,
  unknownId: SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_UNKNOWN,
};

export const ClientIdTypeMap: Partial<Record<RelaySubscriberUploadClientIdType, string>> = {
  [ClientIdType.clientId]: 'Client User ID',
  [ClientIdType.customId]: 'Custom ID',
  [ClientIdType.klaviyoId]: 'Klaviyo ID',
  [ClientIdType.shopifyId]: 'Shopify ID',
  [ClientIdType.salesforceMarketingCloudSubscriberId]: 'Salesforce Marketing Cloud Subscriber ID',
  [ClientIdType.cordialCustomerId]: 'Cordial Customer ID',
};

export enum TypeOptionDisplayValue {
  boolean = 'boolean',
  date = 'date',
  enum = 'string (predefined value)',
  id = 'ID',
  number = 'number',
  string = 'string',
}

export const typeOptions = [
  TypeOptionDisplayValue.string,
  TypeOptionDisplayValue.number,
  TypeOptionDisplayValue.date,
  TypeOptionDisplayValue.boolean,
  TypeOptionDisplayValue.id,
];

type DataTypeKey = RelayCustomPropertyType | TypeOptionDisplayValue;

export const DataTypeMap: Partial<Record<DataTypeKey, string>> = {
  TYPE_BOOL: TypeOptionDisplayValue.boolean,
  TYPE_DATE: TypeOptionDisplayValue.date,
  TYPE_ENUM: TypeOptionDisplayValue.enum,
  TYPE_NUMBER: TypeOptionDisplayValue.number,
  TYPE_STRING: TypeOptionDisplayValue.string,
  TYPE_UNKNOWN: TypeOptionDisplayValue.string,
  ID: TypeOptionDisplayValue.id,
};

export enum SubscriberUploadDestinationType {
  DESTINATION_TYPE_EMAIL = 'DESTINATION_TYPE_EMAIL',
  DESTINATION_TYPE_PHONE = 'DESTINATION_TYPE_PHONE',
}

export const getContactDetailsProperties = (
  isIntlBulkUploadEnabled?: boolean
): RelayWellKnownPropertyType[] => {
  return [
    WELL_KNOWN_PROPERTY_FIRST_NAME,
    WELL_KNOWN_PROPERTY_LAST_NAME,
    WELL_KNOWN_PROPERTY_FULL_NAME,
    WELL_KNOWN_PROPERTY_CITY,
    WELL_KNOWN_PROPERTY_COUNTRY,
    WELL_KNOWN_PROPERTY_REGION,
    WELL_KNOWN_PROPERTY_ZIP,
    WELL_KNOWN_PROPERTY_TIMEZONE,
    ...(isIntlBulkUploadEnabled ? [WELL_KNOWN_PROPERTY_LANGUAGE] : []),
  ];
};

type ProfileProperty = RelaySubscriberUploadClientIdType | RelayWellKnownPropertyType;

export const profileDetailsProperties: ProfileProperty[] = [
  SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_KLAVIYO_ID,
  SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_CLIENT_USER_ID,
  SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_SHOPIFY_ID,
  SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_SALESFORCE_MARKETING_CLOUD_SUBSCRIBER_ID,
  SUBSCRIBER_UPLOAD_CLIENT_ID_TYPE_CORDIAL_CUSTOMER_ID,
  WELL_KNOWN_PROPERTY_TYPES_KLAVIYO_LAST_EMAIL_OPEN,
  WELL_KNOWN_PROPERTY_TYPES_MAILCHIMP_MEMBER_RATING,
];

export type PropertyOption = {
  value:
    | string
    | RelayWellKnownPropertyType
    | RelaySubscriberUploadClientIdType
    | SubscriberUploadDestinationType;
  displayName: string;
  dataType?: string;
  pinned?: boolean;
};

export const NEW_CUSTOM_ATTRIBUTE = 'New Custom Attribute';

export enum FIELD_PROPERTY_KEYS {
  NEW_CUSTOM_DETAILS = 'New Custom Details',
  CONTACT_DETAILS = 'Contact Details',
  PROFILE_DETAILS = 'Profile Details',
  SOURCE_DETAILS = 'Source Details',
  CUSTOM_DETAILS = 'Custom Details',
}

export interface FieldPropertyOptions {
  [FIELD_PROPERTY_KEYS.NEW_CUSTOM_DETAILS]?: PropertyOption[];
  [FIELD_PROPERTY_KEYS.CONTACT_DETAILS]?: PropertyOption[];
  [FIELD_PROPERTY_KEYS.PROFILE_DETAILS]?: PropertyOption[];
  [FIELD_PROPERTY_KEYS.SOURCE_DETAILS]?: PropertyOption[];
  [FIELD_PROPERTY_KEYS.CUSTOM_DETAILS]?: PropertyOption[];
}
