/**
 * @generated SignedSource<<36feb37fd629f90b72e644e1487ccefd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type SubscriberUploadJobStatus = "STATUS_ABANDONED" | "STATUS_COMPLETE" | "STATUS_INVALID" | "STATUS_IN_PROGRESS" | "STATUS_PROCESS" | "STATUS_UNKNOWN" | "STATUS_UPLOADED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UploadStatusLabel_getStatusMetaData_SettingsUI_SubscriberUploadJob$data = {
  readonly status: SubscriberUploadJobStatus;
  readonly " $fragmentType": "UploadStatusLabel_getStatusMetaData_SettingsUI_SubscriberUploadJob";
};
export type UploadStatusLabel_getStatusMetaData_SettingsUI_SubscriberUploadJob$key = {
  readonly " $data"?: UploadStatusLabel_getStatusMetaData_SettingsUI_SubscriberUploadJob$data;
  readonly " $fragmentSpreads": FragmentRefs<"UploadStatusLabel_getStatusMetaData_SettingsUI_SubscriberUploadJob">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "UploadStatusLabel_getStatusMetaData_SettingsUI_SubscriberUploadJob"
};

(node as any).hash = "27dfc3641e5cdfe1884a6c358f820746";

export default node;
