import { createContext, Dispatch } from 'react';
import { Mapping, MappingTableFormState } from '../MappingTableForm';
import {
  ClientIdType,
  WellKnownPropertyType,
  FieldPropertyOptions,
  SubscriberUploadDestinationType,
} from '../MappingTableForm/constants';
import { ErrorMappings } from '../MappingTableForm/utils/validateForm';

export type UploadType = 'UPLOAD_TYPE_NEW' | 'UPLOAD_TYPE_EXISTING';

export enum SubscriptionType {
  SUBSCRIPTION_TYPE_TEXT_MARKETING = 'SUBSCRIPTION_TYPE_TEXT_MARKETING',
  SUBSCRIPTION_TYPE_TEXT_TRANSACTIONAL = 'SUBSCRIPTION_TYPE_TEXT_TRANSACTIONAL',
  SUBSCRIPTION_TYPE_EMAIL_MARKETING = 'SUBSCRIPTION_TYPE_EMAIL_MARKETING',
  SUBSCRIPTION_TYPE_UNKNOWN = 'SUBSCRIPTION_TYPE_UNKNOWN',
}

export enum SubscriptionTypeName {
  SUBSCRIPTION_TYPE_TEXT_MARKETING = 'Text marketing subscription',
  SUBSCRIPTION_TYPE_TEXT_TRANSACTIONAL = 'Text transactional subscription',
  SUBSCRIPTION_TYPE_EMAIL_MARKETING = 'Email marketing subscription',
  SUBSCRIPTION_TYPE_EMAIL_TRANSACTIONAL = 'Email transactional subscription',
  SUBSCRIPTION_TYPE_UNKNOWN = '',
}

export enum SubscriptionTypeDescription {
  SUBSCRIPTION_TYPE_TEXT_MARKETING = 'This type of subscriber is allowed to receive promotional text messages, such as product announcements, sales, available offers, and so on.',
  SUBSCRIPTION_TYPE_TEXT_TRANSACTIONAL = 'This type of subscriber is allowed to receive text messages about the status of their order, such as an order number or shipping confirmation.',
  SUBSCRIPTION_TYPE_EMAIL_MARKETING = 'Subscribers will be eligible to receive promotional emails.',
  SUBSCRIPTION_TYPE_UNKNOWN = '',
}

export enum SubscriptionFlow {
  SUBSCRIPTION_FLOW_SILENT_OPT_IN = 'SUBSCRIPTION_FLOW_SILENT_OPT_IN',
  SUBSCRIPTION_FLOW_SINGLE_OPT_IN = 'SUBSCRIPTION_FLOW_SINGLE_OPT_IN',
  SUBSCRIPTION_FLOW_DOUBLE_OPT_IN = 'SUBSCRIPTION_FLOW_DOUBLE_OPT_IN',
  SUBSCRIPTION_FLOW_UNKNOWN = 'SUBSCRIPTION_FLOW_UNKNOWN',
}

export enum SubscriptionFlowName {
  SUBSCRIPTION_FLOW_SILENT_OPT_IN = 'Silent Opt In',
  SUBSCRIPTION_FLOW_SINGLE_OPT_IN = 'Legal and Welcome Journey',
  SUBSCRIPTION_FLOW_DOUBLE_OPT_IN = 'Double Opt In',
  SUBSCRIPTION_FLOW_UNKNOWN = '',
}

export enum SubscriptionFlowDescription {
  SUBSCRIPTION_FLOW_SILENT_OPT_IN = 'No messages will be sent to subscribers upon upload. Subscribers will automatically be eligible to receive other marketing messages.',
  SUBSCRIPTION_FLOW_SINGLE_OPT_IN = 'Subscribers will receive legal and welcome messages immediately upon upload. Make sure to assign an offer to the upload, if the welcome journey contains an offer.',
  SUBSCRIPTION_FLOW_DOUBLE_OPT_IN = "Subscribers will receive a message to reply with 'Y' to subscribe to recurring automated personalized marketing alerts through SMS. Once they reply 'Y', they'll receive legal and welcome messages.",
  SUBSCRIPTION_FLOW_UNKNOWN = '',
}

export const SubscriptionFlowDescriptions: Partial<
  Record<
    SubscriptionType,
    Partial<Record<SubscriptionFlow, { title: string; description: string }>>
  >
> = {
  SUBSCRIPTION_TYPE_EMAIL_MARKETING: {
    SUBSCRIPTION_FLOW_SILENT_OPT_IN: {
      title: SubscriptionFlowName.SUBSCRIPTION_FLOW_SILENT_OPT_IN,
      description: SubscriptionFlowDescription.SUBSCRIPTION_FLOW_SILENT_OPT_IN,
    },
    SUBSCRIPTION_FLOW_SINGLE_OPT_IN: {
      title: 'Welcome Journey',
      description:
        'Subscriber will enter the welcome journey immediately upon upload. Make sure to assign an offer to the upload, if the welcome journey contains an offer.',
    },
  },
  SUBSCRIPTION_TYPE_TEXT_MARKETING: {
    SUBSCRIPTION_FLOW_DOUBLE_OPT_IN: {
      title: SubscriptionFlowName.SUBSCRIPTION_FLOW_DOUBLE_OPT_IN,
      description: SubscriptionFlowDescription.SUBSCRIPTION_FLOW_DOUBLE_OPT_IN,
    },
    SUBSCRIPTION_FLOW_SILENT_OPT_IN: {
      title: SubscriptionFlowName.SUBSCRIPTION_FLOW_SILENT_OPT_IN,
      description: SubscriptionFlowDescription.SUBSCRIPTION_FLOW_SILENT_OPT_IN,
    },
    SUBSCRIPTION_FLOW_SINGLE_OPT_IN: {
      title: SubscriptionFlowName.SUBSCRIPTION_FLOW_SINGLE_OPT_IN,
      description: SubscriptionFlowDescription.SUBSCRIPTION_FLOW_SINGLE_OPT_IN,
    },
  },
};

export enum SubscriberUploadType {
  RESET_STATE,
  RESET_FILE_STATE,
  SET_ACTIVE_STEP,
  SET_COLUMNS,
  SET_CONTINUE,
  SET_DUPLICATE_ROWS,
  SET_FILE_ID,
  SET_FILE,
  SET_FIRST_ROW,
  SET_INITIAL_MAPPINGS,
  SET_INITIAL_VERIFICATION_PASSED,
  SET_INVALID_CSV,
  SET_INVALID_ROWS,
  SET_IS_EMAIL_ONLY,
  SET_IS_ENQUEUING_BATCH_UPLOAD,
  SET_IS_FETCHING_MAPPING,
  SET_IS_UPLOADING_FILE,
  SET_IS_VALIDATING_FILE,
  SET_LOCATION_FIELD_WARNING,
  SET_LOCALE_HEADER_WARNING,
  SET_MAPPING_DATA_TYPES,
  SET_MAPPING_OPTIONS,
  SET_MAPPINGS_FIELD_ERRORS,
  SET_MAPPINGS_FORM_ERROR,
  SET_MAPPINGS,
  SET_OFFER,
  SET_WELCOME_JOURNEY_ID,
  SET_SUBSCRIPTION_FLOW,
  SET_SUBSCRIPTION_TAG,
  SET_SUBSCRIPTION_TYPE,
  SET_TOTAL_ROWS,
  SET_UPLOAD_TYPE,
  SET_VALID_ROWS,
  SET_COUNTRY_CODE,
  SET_LANGUAGE,
  SET_LANGUAGE_OPTIONS,
  SET_DEFAULT_LANGUAGE,
  SET_IS_PARSING_CSV,
}

export interface Offer {
  name: string;
  id: number;
}

type SubscriberUploadAction =
  | { type: SubscriberUploadType.RESET_FILE_STATE }
  | { type: SubscriberUploadType.SET_ACTIVE_STEP; payload: number }
  | { type: SubscriberUploadType.SET_COLUMNS; payload: string[] }
  | { type: SubscriberUploadType.SET_CONTINUE; payload: boolean }
  | { type: SubscriberUploadType.SET_DUPLICATE_ROWS; payload: number }
  | { type: SubscriberUploadType.SET_FILE_ID; payload: string }
  | { type: SubscriberUploadType.SET_FILE; payload: File }
  | { type: SubscriberUploadType.SET_FIRST_ROW; payload: string[] }
  | { type: SubscriberUploadType.SET_INITIAL_MAPPINGS; payload: Mapping[] }
  | { type: SubscriberUploadType.SET_INITIAL_VERIFICATION_PASSED; payload: boolean }
  | { type: SubscriberUploadType.SET_INVALID_CSV; payload: string | null }
  | { type: SubscriberUploadType.SET_INVALID_ROWS; payload: number }
  | { type: SubscriberUploadType.SET_IS_EMAIL_ONLY; payload: boolean }
  | { type: SubscriberUploadType.SET_IS_ENQUEUING_BATCH_UPLOAD; payload: boolean }
  | { type: SubscriberUploadType.SET_IS_FETCHING_MAPPING; payload: boolean }
  | { type: SubscriberUploadType.SET_IS_UPLOADING_FILE; payload: boolean }
  | { type: SubscriberUploadType.SET_IS_VALIDATING_FILE; payload: boolean }
  | { type: SubscriberUploadType.SET_LOCATION_FIELD_WARNING; payload: string }
  | { type: SubscriberUploadType.SET_LOCALE_HEADER_WARNING; payload: string }
  | { type: SubscriberUploadType.SET_MAPPING_OPTIONS; payload: FieldPropertyOptions | null }
  | { type: SubscriberUploadType.SET_MAPPING_DATA_TYPES; payload: Record<string, string> }
  | { type: SubscriberUploadType.SET_MAPPINGS_FIELD_ERRORS; payload: ErrorMappings | null }
  | { type: SubscriberUploadType.SET_MAPPINGS_FORM_ERROR; payload: string }
  | { type: SubscriberUploadType.SET_MAPPINGS; payload: Mapping[] }
  | { type: SubscriberUploadType.SET_OFFER; payload: Offer | null }
  | { type: SubscriberUploadType.SET_WELCOME_JOURNEY_ID; payload: string | null }
  | { type: SubscriberUploadType.SET_SUBSCRIPTION_FLOW; payload: SubscriptionFlow }
  | { type: SubscriberUploadType.SET_SUBSCRIPTION_TAG; payload: string }
  | { type: SubscriberUploadType.SET_SUBSCRIPTION_TYPE; payload: SubscriptionType }
  | { type: SubscriberUploadType.SET_TOTAL_ROWS; payload: number }
  | { type: SubscriberUploadType.SET_UPLOAD_TYPE; payload: UploadType }
  | { type: SubscriberUploadType.SET_VALID_ROWS; payload: number }
  | { type: SubscriberUploadType.SET_COUNTRY_CODE; payload: string }
  | { type: SubscriberUploadType.SET_LANGUAGE_OPTIONS; payload: string[] }
  | { type: SubscriberUploadType.SET_DEFAULT_LANGUAGE; payload: string }
  | { type: SubscriberUploadType.SET_LANGUAGE; payload: string }
  | { type: SubscriberUploadType.SET_IS_PARSING_CSV; payload: boolean };

export interface SubscriberUploadState {
  activeStep: number;
  back: boolean;
  columns: string[] | null;
  continue: boolean;
  duplicateRows: number;
  file: File | null;
  fileId: string;
  firstRow: string[] | null;
  initialMappings: Mapping[] | null;
  initialVerificationPassed: boolean;
  invalidCsv: string | null;
  invalidRows: number;
  isEmailOnly: boolean;
  isEnqueuingBatchUpload: boolean;
  isFetchingMapping: boolean;
  isParsingCsv: boolean;
  isUploadingFile: boolean;
  isValidatingFile: boolean;
  locationFieldWarning: string;
  localeHeaderWarning: string;
  mappingOptions: FieldPropertyOptions | null;
  mappingOptionsDataTypeMap: Record<string, string>;
  mappings: Mapping[] | null;
  mappingsFieldErrors: ErrorMappings | null;
  mappingsFormError: string;
  welcomeJourneyId: string | null;
  offer: Offer | null;
  subscriptionFlow: SubscriptionFlow;
  subscriptionTag: string;
  subscriptionType: SubscriptionType;
  totalRows: number;
  uploadType: UploadType | null;
  validRows: number;
  countryCode: string;
  language: string;
  languageOptions: string[];
  defaultLanguage: string;
}

export const initialState: SubscriberUploadState = {
  activeStep: 0,
  back: true,
  columns: null,
  continue: false,
  duplicateRows: 0,
  file: null,
  fileId: '',
  firstRow: null,
  initialMappings: null,
  initialVerificationPassed: false,
  invalidCsv: null,
  invalidRows: 0,
  isEmailOnly: false,
  isEnqueuingBatchUpload: false,
  isFetchingMapping: false,
  isParsingCsv: false,
  isUploadingFile: false,
  isValidatingFile: false,
  locationFieldWarning: '',
  localeHeaderWarning: '',
  mappingOptions: null,
  mappingOptionsDataTypeMap: {},
  mappings: null,
  mappingsFieldErrors: null,
  mappingsFormError: '',
  welcomeJourneyId: null,
  offer: null,
  subscriptionFlow: SubscriptionFlow.SUBSCRIPTION_FLOW_SILENT_OPT_IN,
  subscriptionTag: '',
  subscriptionType: SubscriptionType.SUBSCRIPTION_TYPE_UNKNOWN,
  totalRows: 0,
  uploadType: null,
  validRows: 0,
  countryCode: '',
  language: '',
  languageOptions: [],
  defaultLanguage: '',
};

export const reducer = (
  state: SubscriberUploadState,
  action: SubscriberUploadAction
): SubscriberUploadState => {
  switch (action.type) {
    case SubscriberUploadType.RESET_FILE_STATE:
      return {
        ...initialState,
        activeStep: state.activeStep,
        back: state.back,
        countryCode: state.countryCode,
        language: state.language,
        languageOptions: state.languageOptions,
        defaultLanguage: state.defaultLanguage,
        subscriptionFlow: state.subscriptionFlow,
        subscriptionType: state.subscriptionType,
        initialVerificationPassed: state.initialVerificationPassed,
        uploadType: state.uploadType,
      };
    case SubscriberUploadType.SET_CONTINUE:
      return { ...state, continue: action.payload };
    case SubscriberUploadType.SET_ACTIVE_STEP:
      return { ...state, activeStep: action.payload };
    case SubscriberUploadType.SET_DUPLICATE_ROWS:
      return { ...state, duplicateRows: action.payload };
    case SubscriberUploadType.SET_COLUMNS:
      return { ...state, columns: action.payload };
    case SubscriberUploadType.SET_FILE:
      return { ...state, file: action.payload };
    case SubscriberUploadType.SET_FILE_ID:
      // Set continue status based on payload and initial validation state
      return {
        ...state,
        fileId: action.payload,
        continue: Boolean(action.payload) && state.initialVerificationPassed,
      };
    case SubscriberUploadType.SET_FIRST_ROW:
      return { ...state, firstRow: action.payload };
    case SubscriberUploadType.SET_LOCATION_FIELD_WARNING:
      return { ...state, locationFieldWarning: action.payload };
    case SubscriberUploadType.SET_LOCALE_HEADER_WARNING:
      return { ...state, localeHeaderWarning: action.payload };
    case SubscriberUploadType.SET_INITIAL_MAPPINGS:
      return { ...state, initialMappings: action.payload };
    case SubscriberUploadType.SET_INITIAL_VERIFICATION_PASSED:
      return { ...state, initialVerificationPassed: action.payload };
    case SubscriberUploadType.SET_INVALID_CSV:
      return { ...state, invalidCsv: action.payload };
    case SubscriberUploadType.SET_INVALID_ROWS:
      return { ...state, invalidRows: action.payload };
    case SubscriberUploadType.SET_IS_FETCHING_MAPPING:
      return {
        ...state,
        isFetchingMapping: action.payload,
        // disable clicking back or continue during api call
        back: !action.payload,
        continue: !action.payload,
      };
    case SubscriberUploadType.SET_IS_VALIDATING_FILE:
      return {
        ...state,
        isValidatingFile: action.payload,
        back: !action.payload,
        continue: !action.payload,
      };
    case SubscriberUploadType.SET_IS_UPLOADING_FILE:
      return {
        ...state,
        isUploadingFile: action.payload,
      };
    case SubscriberUploadType.SET_IS_ENQUEUING_BATCH_UPLOAD:
      return {
        ...state,
        isEnqueuingBatchUpload: action.payload,
        back: !action.payload,
        continue: !action.payload,
      };
    case SubscriberUploadType.SET_IS_EMAIL_ONLY:
      return { ...state, isEmailOnly: action.payload };
    case SubscriberUploadType.SET_MAPPING_OPTIONS:
      return { ...state, mappingOptions: action.payload };
    case SubscriberUploadType.SET_MAPPING_DATA_TYPES:
      return { ...state, mappingOptionsDataTypeMap: action.payload };
    case SubscriberUploadType.SET_MAPPINGS:
      return { ...state, mappings: action.payload };
    case SubscriberUploadType.SET_MAPPINGS_FIELD_ERRORS:
      return { ...state, mappingsFieldErrors: action.payload };
    case SubscriberUploadType.SET_MAPPINGS_FORM_ERROR:
      return { ...state, mappingsFormError: action.payload };
    case SubscriberUploadType.SET_SUBSCRIPTION_TYPE:
      return {
        ...state,
        subscriptionType: action.payload,

        // reset subsequent options
        subscriptionFlow: SubscriptionFlow.SUBSCRIPTION_FLOW_SILENT_OPT_IN,
        offer: null,
        subscriptionTag: '',
        welcomeJourneyId: null,
      };
    case SubscriberUploadType.SET_SUBSCRIPTION_FLOW:
      return { ...state, subscriptionFlow: action.payload };
    case SubscriberUploadType.SET_OFFER:
      return { ...state, offer: action.payload };
    case SubscriberUploadType.SET_WELCOME_JOURNEY_ID:
      return { ...state, welcomeJourneyId: action.payload };
    case SubscriberUploadType.SET_SUBSCRIPTION_TAG:
      return { ...state, subscriptionTag: action.payload };
    case SubscriberUploadType.SET_TOTAL_ROWS:
      return { ...state, totalRows: action.payload };
    case SubscriberUploadType.SET_UPLOAD_TYPE:
      return { ...initialState, uploadType: action.payload };
    case SubscriberUploadType.SET_VALID_ROWS:
      return { ...state, validRows: action.payload };
    case SubscriberUploadType.SET_COUNTRY_CODE:
      return { ...state, countryCode: action.payload };
    case SubscriberUploadType.SET_LANGUAGE:
      return { ...state, language: action.payload };
    case SubscriberUploadType.SET_LANGUAGE_OPTIONS:
      return { ...state, languageOptions: action.payload };
    case SubscriberUploadType.SET_DEFAULT_LANGUAGE:
      return { ...state, defaultLanguage: action.payload };
    case SubscriberUploadType.SET_IS_PARSING_CSV:
      return { ...state, isParsingCsv: action.payload };
    default:
      return state;
  }
};

export const SubscriberUploadContext = createContext<{
  state: SubscriberUploadState;
  dispatch: Dispatch<SubscriberUploadAction>;
}>({ state: initialState, dispatch: () => {} });

export const initialValues: MappingTableFormState = {
  mappings: [
    {
      header: 'Phone',
      fieldProperty: SubscriberUploadDestinationType.DESTINATION_TYPE_PHONE,
      fieldName: '',
      type: 'string',
      includeColumn: true,
    },
    {
      header: 'Email',
      fieldProperty: SubscriberUploadDestinationType.DESTINATION_TYPE_EMAIL,
      fieldName: '',
      type: 'string',
      includeColumn: true,
    },
    {
      header: 'Klaviyo ID',
      fieldProperty: ClientIdType.klaviyoId,
      fieldName: '',
      type: 'string',
      includeColumn: true,
    },
    {
      header: 'First Name',
      fieldProperty: WellKnownPropertyType.firstName,
      fieldName: '',
      type: 'string',
      includeColumn: true,
    },
    {
      header: 'Last Name',
      fieldProperty: WellKnownPropertyType.lastName,
      fieldName: '',
      type: 'string',
      includeColumn: true,
    },
    {
      header: 'Organization',
      fieldProperty: '78',
      fieldName: '',
      type: 'string',
      includeColumn: true,
    },
    {
      header: 'Title',
      fieldProperty: '34',
      fieldName: '',
      type: 'string',
      includeColumn: true,
    },
    {
      header: 'Location',
      fieldProperty: '12',
      fieldName: '',
      type: 'string',
      includeColumn: true,
    },
    {
      header: 'Test',
      fieldProperty: 'New Custom Attribute',
      fieldName: 'Test',
      type: 'string',
      includeColumn: true,
    },
  ],
  samples: [
    [
      '+15555555555',
      'test@test.gmail.com',
      'Awad2G',
      'Jonathan',
      'Hancock',
      'The First Continental Congress was a meeting of delegates from 12 of the 13 British colonies that became the United States.',
      'Author',
      'Alameda County',
      'google',
    ],
  ],
};
